import React from "react";

import Navigation from "../components/Navigation";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
    <Layout>
        <SEO title="Impressum" />
        <Navigation path="/impressum" />
        <h1>Impressum</h1>
        <p>
            Diese Seite wird betrieben von:
        </p>
        <p>
            <strong><a href="https://eduflux.de">eduFlux GmbH</a></strong><br/>
            Friedrich-Ebert-Straße 55<br/>
            42103 Wuppertal
        </p>
        <p>
            AG Wuppertal HRB 34271
        </p>
        <p>
            E-Mail: <a href="mailto:info@fehlzeitenerfassung.de">info@fehlzeitenerfassung.de </a><br/>
            Tel: +49-(0)2333-9242360<br/>
            Fax: +49-(0)2333-306867-9
        </p>
        <p>
            Geschäftsführer: Dr. Nikolas List
        </p>
    </Layout>
);

export default IndexPage;
