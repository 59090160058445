import React from "react";
import PropTypes from "prop-types";
import globalStyles from "./styles";
//import { Link } from "gatsby";
import Link from "@material-ui/core/Link";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
//import BreadcrumbsHome from "./breadcrumbs";

import Main from "./main";
import Logo from "./Logo";
import Footer from "./footer";
import "../styles/layout.scss";
import InfoTitle from "./InfoTitle";
import InfoBar from "./InfoBar";

const Layout = ({ children, parentPath, header  }) => {
    const classes = globalStyles();
    let link;
    if(header === "Informationen zum Datenschutz" ||
    header === "Nutzungsbedingungen" ||
    header === "Impressum" ||
    header === undefined){
        link = (<Link className="zurueckButton" tabindex="-1" href="/">
            <Fab 
                color="primary"
                variant="extended">
                <KeyboardBackspaceIcon />
                ZURÜCK
            </Fab>
        </Link>);
    }else{
        link = (<Link className="zurueckButton" tabindex="-1" href={parentPath}>
            <Fab color="primary"
                variant="extended">
                <KeyboardBackspaceIcon />
                ZURÜCK
            </Fab>
        </Link>);
    }
    return (
        <Main>
            <Logo />
            <Typography component="main">
                <InfoTitle type="4" />
                <InfoBar wide="white">
                    {header === "Elektronische Unterrichtsdokumentation" ?
                        <h1 className={classes.headerUnterseiten}>Elektronische Unterrichts&shy;dokumentation</h1> :
                        <h1 className={classes.headerUnterseiten}> {header} </h1>}
                    <main>
                        {children}
                    </main>
                </InfoBar>
            </Typography>
            <div className={classes.backButton}>
                { link }
            </div>
            <Footer />
        </Main>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    parentPath: PropTypes.string,
    header: PropTypes.string,
};

export default Layout;
