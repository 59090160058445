import React from "react";
import PropTypes from "prop-types";

import "../styles/main.scss";

const Main = ({ children }) => {

    return (
        <div id="container">
            { children }
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Main;
